import React, { PropsWithChildren } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage } from "react-intl";

import filterActiveIcon from "../../../assets/img/icons/satellite/precision/filter_active.svg";
import filterInactiveIcon from "../../../assets/img/icons/satellite/precision/filter_inactive.svg";
import { MainButton } from "../../../precision-farming/components/advancedFilter/MainButton";
import usePopover from "../../hooks/usePopover";

type Props = {
  advancedFilter: {
    [key: string]: unknown;
  };
  disabled?: boolean;
  filterState: object;
  onAccept?: () => void;
  onReset?: () => void;
  onClose?: () => void;
  setAdvancedFilterState: (val: object) => void;
  setAdvancedFilter: (val: object) => void;
};

const CfFilterV2 = ({
  advancedFilter = {},
  children,
  disabled = false,
  filterState = {},
  onAccept,
  onClose,
  onReset,
  setAdvancedFilter,
  setAdvancedFilterState,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const popover = usePopover();

  React.useEffect(() => {
    setAdvancedFilterState(advancedFilter);
  }, [advancedFilter, setAdvancedFilterState]);

  const getFilterCount = () => {
    let count = 0;
    Object.keys(advancedFilter).forEach((key) => {
      if (!isEmpty(advancedFilter[`${key}`])) {
        count += 1;
      }
    });
    return count;
  };

  const handleFilterAccept = () => {
    popover.handlePopoverClose();
    queueMicrotask(() => updateAdvancedFilter(filterState));
  };

  const handleFilterCancel = () => {
    updateAdvancedFilter({});
    setAdvancedFilterState({});
    popover.handlePopoverClose();
  };

  const updateAdvancedFilter = (values: object) => {
    setAdvancedFilter({ ...values });
  };

  const filterCount = getFilterCount();
  const filterValue = filterCount > 0 ? `(${filterCount})` : "";
  const isActive = popover.isOpen || filterCount > 0;
  return (
    <div style={{ display: "grid" }}>
      <MainButton
        classes={{ custom: classes.mainButton }}
        color="secondary"
        data-test="advanced-filter"
        disabled={disabled}
        isActive={isActive}
        onClick={popover.handlePopoverOpen}
      >
        <span className={classes.mainButtonLabel}>
          {isActive ? (
            <img alt="filter_active" src={filterActiveIcon} />
          ) : (
            <img alt="filter_inactive" src={filterInactiveIcon} />
          )}
          <span style={{ lineHeight: 1.25, marginTop: 2 }}>
            <FormattedMessage
              id="CfFilter.placeholder"
              values={{ count: filterValue }}
            />
          </span>
        </span>
      </MainButton>
      {popover.isOpen ? (
        <Popover
          anchorEl={popover.anchorEl}
          classes={{ paper: classes.popoverPaper }}
          open={popover.isOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => {
            if (onClose) {
              onClose();
            }
            popover.handlePopoverClose();
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {children}
          <div className={classes.buttons}>
            <Button
              id="cancel"
              onClick={() => {
                if (onReset) {
                  onReset();
                }
                handleFilterCancel();
              }}
            >
              <FormattedMessage id="CfFilter.cancelFilter" />
            </Button>
            <Button
              color="primary"
              id="accept"
              onClick={() => {
                if (onAccept) {
                  onAccept();
                }
                handleFilterAccept();
              }}
            >
              <FormattedMessage id="CfFilter.filter" />
            </Button>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  popoverPaper: {
    overflow: "visible",
    marginTop: 7,
  },
  mainButton: {
    alignSelf: "stretch",
  },
  mainButtonLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    gap: 8,
  },
  buttons: {
    float: "right",
    margin: "0px 8px 4px",
  },
}));

export { CfFilterV2 };
