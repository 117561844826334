import { useEffect } from "react";

import { useToggle } from "../../shared/hooks/useToggle";

const useDelayedLoader = (isPending: boolean, delay: number) => {
  const { on, setOff, setOn } = useToggle(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (isPending) {
      timeout = setTimeout(() => {
        setOn();
      }, delay);
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      setOff();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isPending, delay, setOn, setOff]);

  return {
    isDelayed: on,
  };
};

export { useDelayedLoader };
