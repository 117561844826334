import React from "react";

import { SxProps, Theme } from "@mui/material";
import TableCell from "@mui/material/TableCell";

interface Props {
  /**
   * deprecated use sx instead
   */
  classes?: Record<string, string>;
  /**
   * deprecated use sx instead
   */
  className?: string;
  children: React.ReactNode;
  name: string;
  colSpan?: number;
  rowSpan?: number;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}

const CfTableCell = (props: Props) => {
  const {
    children,
    className,
    classes,
    colSpan = 1,
    name,
    rowSpan = 1,
    size = "small",
    sx,
  } = props;

  return (
    <TableCell
      classes={classes}
      className={className}
      colSpan={colSpan}
      key={name}
      rowSpan={rowSpan}
      size={size}
      sx={sx}
    >
      {children}
    </TableCell>
  );
};

export default CfTableCell;
