import { useMutation, queryOptions } from "@tanstack/react-query";

import {
  addAgroevidenceParcels,
  getSeason,
  getExternallyAvailableParcels,
} from "../../../generated/api/satellite";
import { useFarmIds } from "../../../shared/api/client.utils";

const useAddParcelsMutation = () => {
  const farmIds = useFarmIds();

  return useMutation({
    mutationFn: (payload: Parameters<typeof addAgroevidenceParcels>[0]) =>
      addAgroevidenceParcels(payload, farmIds),
  });
};

const seasonQuery = ({
  seasonId,
  ...params
}: { seasonId: Parameters<typeof getSeason>[0] } & Parameters<
  typeof getSeason
>[1]) =>
  queryOptions({
    queryKey: ["precision-farming", "season", seasonId, params],
    queryFn: ({ signal }) => getSeason(seasonId, params, { signal }),
  });

const parcelsQuery = (
  params: Parameters<typeof getExternallyAvailableParcels>[0],
) =>
  queryOptions({
    queryKey: ["precision-farming", "external-parcels", params],
    queryFn: ({ signal }) => getExternallyAvailableParcels(params, { signal }),
  });

export { useAddParcelsMutation, seasonQuery, parcelsQuery };
