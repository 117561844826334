import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";

import {
  DashboardParcelTo,
  InternalCropTo,
  SeasonTo,
} from "../../../../generated/api/satellite";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import {
  SELECT_EMPTY_OBJ,
  SelectObject,
} from "../../../../shared/components/form/FormSelect/types";
import { useDelayedLoader } from "../../../hooks/useDelayedLoader";
import { CropPlaceholder } from "../../dashboard/components/crops/CropPlaceholder";
import { CropsDropdown } from "../../dashboard/components/crops/CropsDropdown";
import { useUpdateSeasonParcelMutation } from "../PrecisionServices.api";

type Props = {
  parcel: DashboardParcelTo;
  cropsOptions: SelectObject<InternalCropTo>[];
  season: SeasonTo;
  queryKey: unknown[];
};

const CropCell = ({ cropsOptions, parcel, queryKey, season }: Props) => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();
  const cropPlaceholderClasses = useCropPlaceholderStyles();
  const [edit, setEdit] = useState(false);

  const [value, setValue] = useState<
    SelectObject<InternalCropTo> | undefined
  >();

  const seasonParcelMutation = useUpdateSeasonParcelMutation({
    queryKey,
    parcelId: parcel.parcelId,
  });

  const { isDelayed } = useDelayedLoader(seasonParcelMutation.isPending, 600);

  useEffect(() => {
    const selectedCrop =
      parcel && cropsOptions.find((c) => c.obj?.id === parcel.cropInternalId);
    const selectedDropdownValue = selectedCrop
      ? ({
          key: selectedCrop?.obj?.id,
          value: selectedCrop?.obj?.name,
          obj: selectedCrop?.obj,
        } as SelectObject<InternalCropTo>)
      : undefined;

    setValue(selectedDropdownValue);
  }, [cropsOptions, parcel]);

  const handleChange = (
    e: React.SyntheticEvent,
    selected?: SelectObject<InternalCropTo>,
  ) => {
    e.stopPropagation();
    setValue(selected ?? SELECT_EMPTY_OBJ);

    seasonParcelMutation.mutate({
      cropInternalId: selected?.obj?.id,
      parcelId: parcel.parcelId,
      seasonId: season.id,
      yieldInTonnes: parcel.yieldInTonnes,
      cropName: selected?.obj?.name ?? "",
    });
  };

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader classes={loaderClasses} size={32} />;
  } else if (edit) {
    Jsx = (
      <CropsDropdown
        cropsOptions={cropsOptions}
        handleChange={handleChange}
        setEdit={setEdit}
        value={value}
      />
    );
  } else if (!edit) {
    Jsx = (
      <CropPlaceholder
        classes={cropPlaceholderClasses}
        selectedOption={value}
        onClick={(e) => {
          e.stopPropagation();
          setEdit(true);
        }}
      />
    );
  }

  return <div className={classes.wrapper}>{Jsx}</div>;
};

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 228,
  },
}));

const useCropPlaceholderStyles = makeStyles(() => ({
  wrapper: {
    height: 56,
    "&:hover": {
      backgroundColor: "#0000000a",
    },
  },
}));

const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export { CropCell };
