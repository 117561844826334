import React from "react";

import { alpha, Drawer, SxProps, Theme } from "@mui/material";

const Sidebar = ({
  children,
  open,
  sx,
}: React.PropsWithChildren<{ open: boolean; sx?: SxProps<Theme> }>) => (
  <Drawer
    anchor="right"
    open={open}
    variant="persistent"
    ModalProps={{
      hideBackdrop: true,
      disableEnforceFocus: true,
    }}
    PaperProps={{
      sx: {
        height: "calc(100% - 208px)",
        top: "unset",
        bottom: 0,
        borderTop: `1px solid ${alpha("#000000", 0.12)}`,
        maxWidth: "100vw",
        overflow: "hidden",
        ...sx,
      },
    }}
  >
    {children}
  </Drawer>
);

export { Sidebar };
