import React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { NO_CROP } from "../../../../../evidence/actions/shared/misc/action.helpers";
import { InternalCropTo } from "../../../../../generated/api/satellite";
import { SelectObject } from "../../../../../shared/components/form/FormSelect/types";
import { COLOR_PRIMARY } from "../../../../../theme";

type Props = {
  selectedOption?: SelectObject<InternalCropTo>;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  classes?: Record<string, string>;
};

const CropPlaceholder = ({
  classes: propClasses,
  onClick,
  selectedOption,
}: Props) => {
  const classes = useStyles();

  const getCropLabel = () => {
    if (
      !selectedOption?.key ||
      selectedOption?.key === NO_CROP.id ||
      selectedOption?.key === ""
    ) {
      return (
        <span style={{ color: "#CECECE" }}>
          <FormattedMessage id="common.noCrop" />
        </span>
      );
    }
    return selectedOption?.value;
  };

  return (
    <button
      className={classNames(propClasses?.wrapper, classes.wrapper)}
      onClick={onClick}
    >
      <span className={classes.text}>{getCropLabel()}</span>
      <ArrowDropDownIcon htmlColor={COLOR_PRIMARY.cleverfarm.main} />
    </button>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: 23,
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    color: "inherit",
    font: "inherit",
    textAlign: "left",
    maxWidth: 228,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#0000000a",
    },
    "&:focus": {
      backgroundColor: "#0000000a",
    },
  },
  text: {
    fontSize: 16,
    letterSpacing: 0.15,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
}));

export { CropPlaceholder };
