import React from "react";

import { Box, SxProps, Theme } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

type Props = {
  color?: CircularProgressProps["color"];
  size?: number;
  sx?: SxProps<Theme>;
  /**
   * deprecated use sx instead
   */
  classes?: {
    wrapper?: string;
    custom?: string;
  };
};

const CfLoader = ({
  classes: propClasses = {},
  color = "primary",
  size = 40,
  sx,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classNames(classes.wrapper, propClasses.wrapper)} sx={sx}>
      <CircularProgress
        classes={{ root: classNames(classes.custom, propClasses.custom) }}
        color={color}
        size={size}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  custom: {},
}));

export default CfLoader;
