import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import {
  DashboardSeasonTo,
  deleteParcelFromSeason,
  getSeasonDashboard,
  GetSeasonDashboardParams,
  updateSeasonParcelMetadata,
} from "../../../generated/api/satellite";
import { getCountedData, useFarmIds } from "../../../shared/api/client.utils";
import { staleTime } from "../../../shared/query-client";

const satelliteServicesKey = ["satellite", "services"];

const servicesQuery = (seasonId: string, params: GetSeasonDashboardParams) =>
  queryOptions({
    queryKey: [...satelliteServicesKey, ...Object.values(params), seasonId],
    queryFn: async () => {
      const data = await getSeasonDashboard(seasonId, params);
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
  });

const useServicesDelete = () =>
  useMutation({
    mutationFn: (params: Parameters<typeof deleteParcelFromSeason>) =>
      deleteParcelFromSeason(...params),
  });

type UseUpdateSeasonType = {
  queryKey: unknown[];
  parcelId: string;
};

const useUpdateSeasonParcelMutation = ({
  parcelId,
  queryKey,
}: UseUpdateSeasonType) => {
  const farmIds = useFarmIds();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      payload: Parameters<typeof updateSeasonParcelMetadata>[0] & {
        cropName: string;
      },
    ) => updateSeasonParcelMetadata(payload, farmIds),
    onMutate: (params) => {
      const snapshot = queryClient.getQueriesData({
        queryKey,
      });

      queryClient.setQueryData(
        queryKey,
        (prev: { data: DashboardSeasonTo[]; count: number }) => {
          const resData = prev.data.map((item) => ({
            ...item,
            parcels: item.parcels.map((parcel) =>
              parcel.parcelId === parcelId
                ? {
                    ...parcel,
                    cropName: params.cropName,
                    cropInternalId: params.cropInternalId,
                    yieldInTonnes: params.yieldInTonnes,
                  }
                : parcel,
            ),
          }));

          return {
            ...prev,
            data: resData,
          };
        },
      );

      return () => {
        queryClient.setQueryData(queryKey, snapshot);
      };
    },
    onError: (error, variables, rollback) => {
      rollback?.();
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
  });
};

export {
  satelliteServicesKey,
  servicesQuery,
  useServicesDelete,
  useUpdateSeasonParcelMutation,
};
