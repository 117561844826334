import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { SatelliteParcelV2 } from "../../../generated/api/satellite";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";

import { PrecisionParcel } from "../../../shared/api/sentinel/precision/precision.types";

const useStyles = makeStyles((theme: Theme) => ({
  parcelDetailButton: {
    padding: 0,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    cursor: "pointer",
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  parcelName: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 4,
  },
}));

interface PrecisionDetailHeadingProps {
  farmId: string;
  parcel: PrecisionParcel | SatelliteParcelV2;
}

const PrecisionDetailHeading: FC<PrecisionDetailHeadingProps> = ({
  farmId,
  parcel,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { id, localName } = parcel;

  const handleClick = () => {
    history.push(`/farm/${farmId}/parcels/${id}/overview`);
    history.go(0);
  };

  return (
    <PageHeading
      dataTest="precisionDetail-heading"
      value={
        <div className={classes.heading}>
          <div className={classes.parcelName}>{localName}</div>
          <a className={classes.parcelDetailButton} onClick={handleClick}>
            <FormattedMessage id="PrecisionDetailHeader.parcelDetail" />
          </a>
        </div>
      }
    />
  );
};

export default PrecisionDetailHeading;
