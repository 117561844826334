import React, { FC, ReactNode, useEffect } from "react";

import { connect } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { bindActionCreators } from "redux";

import { resetPrecision } from "../actions/precision.actions";

import useToggles from "../../../shared/toggles/toggles";
import TOGGLES from "../../../shared/toggles/toggles.const";
import { Thunk } from "../../../types";

import PrecisionTabs, { PRECISION_LISTING_TABS } from "./PrecisionTabs";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

interface Props {
  children: ReactNode;
  ngRedirectToVrf: (parcelId: string[]) => void;
  ngRedirectToVrs: (parcelId: string[]) => void;
  resetPrecision: () => void;
  tab: PRECISION_LISTING_TABS;
  hasPrecision: boolean;
  setSelectedTabIndex: (val?: number) => void;
}

const getSelectedTabIndex = (
  tab: PRECISION_LISTING_TABS,
  dashboardIsActive: boolean,
): number => {
  if (tab === PRECISION_LISTING_TABS.SERVICES) {
    return 0;
  } else if (tab === PRECISION_LISTING_TABS.SEASONS_DASHBOARD) {
    return 1;
  } else if (tab === PRECISION_LISTING_TABS.AS_APPLIED) {
    return dashboardIsActive ? 2 : 1;
  }
  return 0;
};

const PrecisionFarming: FC<Props> = ({
  children,
  hasPrecision,
  ngRedirectToVrf,
  ngRedirectToVrs,
  resetPrecision,
  setSelectedTabIndex,
  tab,
}) => {
  const match = useRouteMatch<{ farmId: string }>();
  const location = useLocation();
  const history = useHistory();
  const [, , isToggleActive] = useToggles();
  const selectedTabIndex = getSelectedTabIndex(
    tab,
    isToggleActive(TOGGLES.SEASON_DASHBOARD_TOGGLE.name),
  );

  useEffect(
    () => () => {
      resetPrecision();
    },
    [resetPrecision],
  );

  useEffect(() => {
    setSelectedTabIndex(selectedTabIndex);
  }, [selectedTabIndex, setSelectedTabIndex]);

  return (
    <PrecisionTabs
      hasPrecision={hasPrecision}
      history={history}
      location={location}
      match={match}
      ngRedirectToVrf={ngRedirectToVrf}
      ngRedirectToVrs={ngRedirectToVrs}
      selectedTabIndex={selectedTabIndex}
      tab={tab}
    >
      {children}
    </PrecisionTabs>
  );
};

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      resetPrecision,
    },
    dispatch,
  );

export default connect(undefined, mapDispatchToProps)(PrecisionFarming);
