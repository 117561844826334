import React, { useContext, useEffect, useMemo, useState } from "react";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getFertilizer,
  getIsFetchingFertilizers,
} from "../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors";
import { getStoreAmounByMaterial } from "../../../../shared/api/stores/stores/stores.selectors";

import {
  createFertilizer,
  updateFertilizer,
  fetchFertilizer,
  resetFertilizer,
  patchFertilizer,
  fetchStoreByMaterial,
} from "../../../actions/catalogues.actions";

import { CATALOGUES_URLS } from "../../../catalogues.constants";

import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import useWidth from "../../../../shared/hooks/useWidth";
import { CfFormikErrors, CfFormikProps } from "../../../../types";
import { CataloguesContext } from "../../../containers/CataloguesWrapper/CataloguesWrapper";
import { StoreAmount } from "../../plantProtection/PlantProtectionDetail/components/StoreAmount";
import Buttons from "../../shared/Buttons";
import CheckboxField from "../../shared/CheckboxField";
import DatePickerField from "../../shared/DatePickerField";
import FormikTextField from "../../shared/FormikTextField";
import NumericField from "../../shared/NumericField";
import NitrogenCategorySelector from "../FertilizersAdvancedFilter/NitrogenCategorySelector/NitrogenCategorySelector";

import FertilizerDetailHeader from "./components/FertilizerDetailHeader";
import { UnitTypeRadioGroup } from "./components/UnitTypeRadioGroup";
import {
  initialEmptyValues,
  mapInitialValues,
  mapRequestBodyUpdateFertilizer,
} from "./FertilizerDetail.services";
import { useFertilizerDetailStyles } from "./FertilizerDetail.styles";
import FertilizerKindSelector from "./FertilizerKindSelector/FertilizerKindSelector";
import RegistrationTypeSelector from "./RegistrationTypeSelector/RegistrationTypeSelector";

import { FertilizerItemFormValues } from "./FertilizerDetail.types";
import {
  EagriFertilizerKindTo,
  EagriFertilizerNitrogenCategoryTo,
  EagriFertilizerRegistrationTypeTo,
  FertilizerDetailTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  isNew: boolean;
}

const FertilizerDetail = ({ isNew }: Props) => {
  const classes = useFertilizerDetailStyles();
  const history = useHistory();
  const width = useWidth();
  const { farmId, langId } = useContext(CataloguesContext);
  const { fertilizerId } = useParams<{ fertilizerId: string }>();
  const showSnackbar = useContext(SnackbarContext);

  const dispatch = useDispatch();
  const fertilizer = useSelector(getFertilizer);
  const isFetching = useSelector(getIsFetchingFertilizers);
  const storeAmount = useSelector(getStoreAmounByMaterial);

  const [isEditing, setIsEditing] = useState(isNew);

  useEffect(() => {
    dispatch(resetFertilizer());
    if (!isNew) {
      dispatch(fetchFertilizer(fertilizerId));
      dispatch(fetchStoreByMaterial(fertilizerId));
    }
  }, [dispatch, fertilizerId, isNew]);

  const initialValues: Partial<FertilizerItemFormValues> = useMemo(() => {
    if (isNew || !fertilizer) return initialEmptyValues;

    return mapInitialValues(fertilizer);
  }, [fertilizer, isNew]);

  const handleFavoriteClick = () => {
    (
      dispatch(
        patchFertilizer(fertilizerId, {
          isFavorite: !fertilizer?.isFavorite,
        }),
      ) as unknown as Promise<unknown>
    ).then(() => dispatch(fetchFertilizer(fertilizerId)));
  };

  const handleHideClick = () => {
    (
      dispatch(
        patchFertilizer(fertilizerId, {
          isHidden: true,
        }),
      ) as unknown as Promise<unknown>
    ).then(() => handleGoBack());
  };

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleResetForm = () => {
    setIsEditing(false);
  };

  const handleSubmit = (values: FertilizerItemFormValues) => {
    const data = mapRequestBodyUpdateFertilizer(values);
    if (isNew) {
      (dispatch(createFertilizer(data)) as unknown as Promise<unknown>).then(
        (res: RSAAResultAction<FertilizerDetailTo>) => {
          if (!res.error && values.isFavorite) {
            const newFertilizerId = res.payload.id;
            dispatch(patchFertilizer(newFertilizerId, { isFavorite: true }));
          }
          handleResponse(res, true);
        },
      );
    } else {
      (
        dispatch(
          updateFertilizer(fertilizerId, data),
        ) as unknown as Promise<unknown>
      ).then((res: RSAAResultAction<FertilizerDetailTo>) =>
        handleResponse(res),
      );
      setIsEditing(false);
    }
  };

  const handleResponse = (
    res: RSAAResultAction<FertilizerDetailTo>,
    newAction = false,
  ) => {
    if (!res.error) {
      if (newAction) handleGoBack();
      return;
    }
    showSnackbar({
      message: (
        <FormattedMessage
          id={`Catalogues.fertilizers.${
            newAction ? "createError" : "updateError"
          }`}
        />
      ),
      isError: true,
    });
  };

  const handleGoBack = () => {
    history.push(`/farm/${farmId}/${CATALOGUES_URLS.fertilizersReact}`);
  };

  const validate = (values: FertilizerItemFormValues) => {
    const errors: CfFormikErrors<FertilizerItemFormValues> = {};
    if (!values.name) {
      errors.name = "validation.required";
    }

    if (!values.nitrogenCategory) {
      errors.nitrogenCategory = "validation.required";
    }

    if (!values.fertilizerKind) {
      errors.fertilizerKind = "validation.required";
    }

    if (!values.unitType) {
      errors.unitType = "validation.required";
    }

    if (!values.registrationType) {
      errors.registrationType = "validation.required";
    }

    return errors;
  };

  const isMobile = width === "xs" || width === "sm";
  const spacingValue = isMobile ? 1 : 0;
  const canUpdate = isNew || fertilizer?.catalogue.canUpdate;

  if (!fertilizer && isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <Formik<Partial<FertilizerItemFormValues>>
      enableReinitialize
      initialValues={initialValues}
      onReset={handleResetForm}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        setFieldValue,
        values,
      }: CfFormikProps<FertilizerItemFormValues>) => {
        const handleChangeName = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setFieldValue("name", event.target.value);
        };

        const handleChangeUnitType = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setFieldValue("unitType", event.target.defaultValue);
        };

        const handleChangeNitrogenCategory = (
          nitrogenCategory: EagriFertilizerNitrogenCategoryTo,
        ) => {
          setFieldValue("nitrogenCategory", nitrogenCategory);
        };

        const handleRegistrationType = (
          registrationType: EagriFertilizerRegistrationTypeTo,
        ) => {
          setFieldValue("registrationType", registrationType);
        };

        const handleChangeFertilizerKindy = (
          fertilizerKind: EagriFertilizerKindTo,
        ) => {
          setFieldValue("fertilizerKind", fertilizerKind);
        };

        const handleChangeCheckbox = (
          event: React.ChangeEvent<HTMLInputElement>,
          name: string,
        ) => {
          setFieldValue(name, event.target.checked);
        };

        const handleChangeFavoriteOnCreate = () => {
          setFieldValue("isFavorite", !values.isFavorite);
        };

        const renderSectionHeading = (messageId: string) => (
          <Grid data-test="sectionHeading" item xs={12}>
            <Typography className={classes.sectionHeading} variant="h5">
              <FormattedMessage id={messageId} />
            </Typography>
          </Grid>
        );

        return (
          <Form>
            <div className={classes.body}>
              <Grid className={classes.headWrapper} container>
                <FertilizerDetailHeader
                  backButtonLabel="Catalogues.fertilizers.detail.backToFertilizers"
                  canUpdate={canUpdate}
                  defaultIsFavorite={values.isFavorite}
                  defaultName={values.name}
                  error={!!errors.name}
                  handleGoBack={handleGoBack}
                  handleStartEdit={handleStartEdit}
                  isEditing={isEditing}
                  itemNamePlaceholder="Catalogues.fertilizers.detail.name"
                  onChange={handleChangeName}
                  handleFavoriteClick={
                    isNew ? handleChangeFavoriteOnCreate : handleFavoriteClick
                  }
                />
              </Grid>
              <Grid
                className={classes.wrapper}
                container
                direction={isMobile ? "column" : "row"}
                spacing={2}
              >
                <Grid
                  container
                  data-test="fertilizer-source"
                  item
                  justifyContent="flex-end"
                  style={{ paddingTop: 8 }}
                  xs={12}
                >
                  <FormattedMessage
                    id={`Catalogues.detail.shared.source.${
                      canUpdate ? "custom" : "eAgri"
                    }`}
                    values={{
                      b: (chunks: string) => (
                        <b style={{ marginLeft: 10 }}>{chunks}</b>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {renderSectionHeading(
                    "Catalogues.fertilizers.detail.compositionSection",
                  )}
                  <Paper className={classes.paper}>
                    <Grid container direction={isMobile ? "column" : "row"}>
                      <Grid
                        container
                        direction="column"
                        item
                        spacing={2}
                        xs={3}
                      >
                        <Grid item>
                          <CheckboxField
                            defaultValues={values.isOrganic}
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.isOrganic"
                            name="isOrganic"
                            onChange={handleChangeCheckbox}
                          />
                        </Grid>
                        <Grid item style={{ paddingRight: 32 }}>
                          <FormikTextField
                            fullWidth={true}
                            isEditing={isEditing}
                            isValidation={true}
                            label="Catalogues.fertilizers.detail.producerTradeName"
                            name="producerTradeName"
                          />
                        </Grid>
                        <Grid className={classes.fieldFormik} item>
                          <UnitTypeRadioGroup
                            defaultValues={values.unitType}
                            error={!!errors.unitType}
                            isEditing={isEditing}
                            onChange={handleChangeUnitType}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        item
                        spacing={2}
                        xs={8}
                      >
                        <Grid item>
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.phFrom"
                            name="phFrom"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.phTo"
                            name="phTo"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.electricConductivity"
                            name="electricConductivity"
                            unit="S"
                          />
                          <NumericField
                            isEditing={isEditing}
                            isWider={true}
                            label="Catalogues.fertilizers.detail.chlorides"
                            name="chlorides"
                            unit="%"
                          />
                        </Grid>
                        <Grid item>
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.n"
                            name="n"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.p2o5"
                            name="p2o5"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.k2o"
                            name="k2o"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.zn"
                            name="zn"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.cu"
                            name="cu"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.fe"
                            name="fe"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.mgo"
                            name="mgo"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.cao"
                            name="cao"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.na2o"
                            name="na2o"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.b"
                            name="b"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.mn"
                            name="mn"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.mo"
                            name="mo"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.se"
                            name="se"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.s"
                            name="s"
                            unit="%"
                          />
                          <NumericField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.combustibleContent"
                            name="combustibleContent"
                            unit="%"
                          />
                          <Grid item style={{ paddingRight: 40 }} xs={5}>
                            <NumericField
                              fullWidth={true}
                              isEditing={isEditing}
                              label="Catalogues.fertilizers.detail.unitConversionCoefficient"
                              name="unitConversionCoefficient"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ paddingRight: 40 }} xs={12}>
                        <FormikTextField
                          fullWidth={true}
                          isEditing={isEditing}
                          label="common.description"
                          name="description"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {renderSectionHeading(
                    "Catalogues.fertilizers.detail.nitrateDirectiveSection",
                  )}
                  <Paper className={classes.paper}>
                    <Grid
                      container
                      direction={isMobile ? "column" : "row"}
                      spacing={spacingValue}
                    >
                      <Grid
                        container
                        direction="column"
                        item
                        spacing={2}
                        style={{ marginRight: 32 }}
                        xs="auto"
                      >
                        <Grid className={classes.datePickerField} item>
                          <DatePickerField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.validFrom"
                            name="validFrom"
                          />
                        </Grid>
                        <Grid className={classes.datePickerField} item>
                          <DatePickerField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.validTo"
                            name="validTo"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        item
                        spacing={2}
                        xs={2}
                      >
                        <Grid item>
                          <CheckboxField
                            defaultValues={values.isExcrement}
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.isExcrement"
                            name="isExcrement"
                            onChange={handleChangeCheckbox}
                          />
                        </Grid>
                        <Grid item>
                          <CheckboxField
                            defaultValues={values.isEco}
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.isEco"
                            name="isEco"
                            onChange={handleChangeCheckbox}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        item
                        spacing={1}
                        xs="auto"
                      >
                        <Grid container direction="row" item>
                          <div className={classes.nitrogenCategorySelector}>
                            <NitrogenCategorySelector
                              defaultValues={values.nitrogenCategory}
                              disabled={!isEditing}
                              error={!!errors.nitrogenCategory}
                              onChange={handleChangeNitrogenCategory}
                              label={
                                <FormattedMessage id="Catalogues.table.fertilizers.column.nitrogenCategory" />
                              }
                            />
                          </div>
                          <div className={classes.registrationTypeSelector}>
                            <RegistrationTypeSelector
                              defaultValues={values.registrationType}
                              disabled={!isEditing}
                              error={!!errors.registrationType}
                              langId={langId}
                              onChange={handleRegistrationType}
                            />
                          </div>
                        </Grid>
                        <Grid container direction="row" item>
                          <div className={classes.nitrogenCategorySelector}>
                            <FertilizerKindSelector
                              defaultValues={values.fertilizerKind}
                              disabled={!isEditing}
                              error={!!errors.fertilizerKind}
                              onChange={handleChangeFertilizerKindy}
                            />
                          </div>
                          <NumericField
                            isEditing={isEditing}
                            isWider={true}
                            label="Catalogues.fertilizers.detail.nitrogenCoefficient"
                            name="nitrogenCoefficient"
                            unit="%"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        item
                        spacing={1}
                        xs="auto"
                      >
                        <Grid container direction="row" item>
                          <FormikTextField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.registrationNumber"
                            name="registrationNumber"
                          />
                          <FormikTextField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.ordinance"
                            name="ordinance"
                          />
                        </Grid>
                        <Grid container direction="row" item>
                          <FormikTextField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.evidenceNumber"
                            name="evidenceNumber"
                          />
                          <FormikTextField
                            isEditing={isEditing}
                            label="Catalogues.fertilizers.detail.applicantTradeName"
                            name="applicantTradeName"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {renderSectionHeading("common.stores")}
                  <Paper className={classes.paper}>
                    <StoreAmount storeAmount={storeAmount} />
                  </Paper>
                </Grid>
              </Grid>
              {isEditing && (
                <Buttons
                  isNew={isNew}
                  onHide={handleHideClick}
                  onHideContextId="Catalogues.fertilizers.detail.hideModal.context"
                  onHideTitleId="Catalogues.fertilizers.detail.hideModal.title"
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { FertilizerDetail };
