import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./seeds.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import {
  CropSeedCreateTo,
  CropSeedPatchTo,
} from "../../../../../generated/api/agroevidence";

import { getSeedsParams } from "./seeds.types";
import { RsaaMethods, RsaaTypes } from "../../../api.types";

export const getSeedsApi = (params: getSeedsParams) => ({
  [RSAA]: {
    endpoint: `catalogues/seeds?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_SEEDS_REQUEST,
      types.GET_SEEDS_SUCCESS,
      types.GET_SEEDS_ERROR,
    ] as RsaaTypes,
  },
});

export const getSeedsSuggestionsApi = (params: getSeedsParams) => ({
  [RSAA]: {
    endpoint: `catalogues/seeds/suggestions?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_SEEDS_REQUEST,
      types.GET_SEEDS_SUCCESS,
      types.GET_SEEDS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetSeedsApi = () => ({
  type: types.RESET_SEEDS,
});

export const updateSeedApi = (seedId: string, params: CropSeedPatchTo) => ({
  [RSAA]: {
    endpoint: `catalogues/seeds/${seedId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_SEED_REQUEST,
      types.UPDATE_SEED_SUCCESS,
      types.UPDATE_SEED_ERROR,
    ] as RsaaTypes,
  },
});

export const createSeedApi = (params: CropSeedCreateTo) => ({
  [RSAA]: {
    endpoint: "catalogues/seeds?",
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_SEED_REQUEST,
      types.CREATE_SEED_SUCCESS,
      types.CREATE_SEED_ERROR,
    ] as RsaaTypes,
  },
});
