import React, { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { getIndex } from "../../selectors/monitoring.selectors";

import {
  BiomonitoringIntervalResponse,
  PrecisionFarmingProduct,
  SeasonWithCropTo,
} from "../../../../generated/api/satellite";
import { useFarmIds } from "../../../../shared/api/client.utils";
import {
  disableHTMLCanvasImageSmoothing,
  enableHTMLCanvasImageSmoothing,
} from "../../../../shared/misc/helpers";
import { BioMonitoringHistoryInfoDialog } from "../../components/BioMonitoring/BioMonitoringHistoryInfoDialog";
import {
  biomonitoringDataQuery,
  cropIntervalsQuery,
} from "../Biomonitoring.api";
import { ChartDataOrMonitoringDataV2 } from "../BioMonitoringV2";

import { MonitorIntervalType } from "./BiomonitoringWrapper/BiomonitoringWrapperV2";
import { isPlanetIndexType } from "./BiomonitoringWrapper/helpers";
import { IndexTypeDataV2Type } from "./types";
import { useLocking } from "./useLocking";

type Props = {
  selectedInterval?: MonitorIntervalType;
  parcelId: string;
  seasonId?: string;
  setCropIntervals: (val: SeasonWithCropTo[]) => void;
  setCropIntervalsIsFetching: (val: boolean) => void;
};

const useBioMonitoringV2 = ({
  parcelId,
  seasonId,
  selectedInterval,
  setCropIntervals,
  setCropIntervalsIsFetching,
}: Props) => {
  const index = useSelector(getIndex);
  const dateFrom = selectedInterval?.dateFrom;

  const farmIds = useFarmIds();
  const biomonitoringData = useQuery(
    biomonitoringDataQuery(parcelId, {
      farmIds: farmIds.farmIds,
      type: index,
      "season-id": seasonId,
    }),
  );

  const data = biomonitoringData.data?.data ?? [];

  const cropIntervalsData = useQuery(
    cropIntervalsQuery(parcelId, {
      farmIds: farmIds.farmIds,
      product: PrecisionFarmingProduct.GISAT_BIOPHYSICAL_MONITORING,
    }),
  );

  const cropIntervals = cropIntervalsData.data?.data ?? [];
  const cropIntervalsIsFetching =
    cropIntervalsData.isPending ||
    cropIntervalsData.isFetching ||
    cropIntervalsData.isLoading;

  const { isInit } = useLocking({
    isInitCustomCondition: !isPlanetIndexType(index),
    cropIntervals,
    selectedIntervalId: selectedInterval?.selectedIntervalId,
    isFetchingCropIntervals: cropIntervalsIsFetching,
  });

  useEffect(() => {
    setCropIntervalsIsFetching(cropIntervalsIsFetching);
  }, [cropIntervalsIsFetching, setCropIntervalsIsFetching]);

  useEffect(() => {
    setCropIntervals(cropIntervals);
  }, [cropIntervals, setCropIntervals]);

  useEffect(() => {
    if (!isInit) {
      disableHTMLCanvasImageSmoothing();
      cropIntervalsData.refetch();
    }
    return () => {
      enableHTMLCanvasImageSmoothing();
    };
  }, [isInit]);

  useEffect(() => {
    if (isInit && dateFrom) {
      biomonitoringData.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, isInit]);

  // const overallImagesError =
  //   BioMonitoringImageErrorService.getOverallImagesError(data, index);
  // const cropHarvestedError =
  //   BioMonitoringImageErrorService.checkCropHarvestedError(data);
  const lastImageDateTo = "TODO-VALIDITY";

  const infoDialogContent = (
    <BioMonitoringHistoryInfoDialog.Root index={index} />
  );

  const resData = data
    .filter((item: BiomonitoringIntervalResponse) => item?.snapshots?.length)
    .map((item) => ({
      crop: item.crop,
      dateFrom: item.dateFrom,
      dateTo: item.dateTo,
      imgExtent: item.snapshots[0].imgExtent,
      zones: item.snapshots[0].zones,
      status: item.status,
      value: item.snapshots[0].value,
      imgPath: item.snapshots[0].imgPath,
      indexType: item.snapshots[0].indexType,
    }));

  const graphData = resData.slice().reverse() as ChartDataOrMonitoringDataV2[];

  const isFetching =
    biomonitoringData.isFetching ||
    biomonitoringData.isPending ||
    biomonitoringData.isLoading ||
    cropIntervalsIsFetching;

  return {
    isFetching,
    overallImagesError: null,
    cropHarvestedError: null,
    lastImageDateTo,
    data: resData,
    infoDialogContent,
    graphData,
  } as IndexTypeDataV2Type;
};

export { useBioMonitoringV2 };
