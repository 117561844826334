import { z } from "zod";

import {
  CROPS_KEY,
  PARCELS_PER_PAGE_KEY,
  SEASONS_PER_PAGE_KEY,
  TEXT_FILTER_KEY,
  PARCELS_PAGE_KEY,
  SEASONS_OFFSET_KEY,
} from "./url.constants";

import { useSearchParams } from "../../../hooks/useSearchParams";
import { AdvancedFilterType } from "../../../types";

const SEASONS_PER_PAGE_DEFAULT = 0;
const SEASONS_OFFSET_DEFAULT = 0;

const PARCELS_PER_PAGE_DEFAULT = 100;
const PARCELS_PAGE_DEFAULT = 0;

const paramsSchema = z.object({
  seasonsPerPage: z.coerce.number().default(SEASONS_PER_PAGE_DEFAULT),
  seasonsOffset: z.coerce.number().default(SEASONS_OFFSET_DEFAULT),
  parcelsPage: z.coerce.number().default(PARCELS_PAGE_DEFAULT),
  parcelsPerPage: z
    .preprocess(
      (val) => parseInt(val as string, 10),
      z.union([
        z.literal(10),
        z.literal(25),
        z.literal(50),
        z.literal(PARCELS_PER_PAGE_DEFAULT),
      ]),
    )
    .default(PARCELS_PER_PAGE_DEFAULT),
  textFilter: z.string().optional(),
  advancedFilter: z
    .object({
      crops: z.array(z.string()).default([]),
    })
    .optional()
    .default({ crops: [] }),
});

const usePageParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rawParams = Object.fromEntries(searchParams);

  const advancedFilter = {
    crops: rawParams?.[CROPS_KEY] ? rawParams[CROPS_KEY].split(",") : [],
  };

  const safeParamsResult = paramsSchema.safeParse({
    ...rawParams,
    advancedFilter,
  });

  if (!safeParamsResult.success) {
    return {
      seasonsPerPage: SEASONS_PER_PAGE_DEFAULT,
      setSeasonsPerPage: () => {},
      seasonsOffset: SEASONS_OFFSET_DEFAULT,
      setSeasonsOffset: () => {},
      parcelsPage: PARCELS_PAGE_DEFAULT,
      setParcelsPage: () => {},
      parcelsPerPage: PARCELS_PER_PAGE_DEFAULT,
      setParcelsPerPage: () => {},
      textFilter: "",
      setTextFilter: () => {},
      advancedFilter: { crops: [] },
      setAdvancedFilter: () => {},
    };
  }

  const safeParams = safeParamsResult.data;

  const setSeasonsPerPage = (value: number) => {
    setSearchParams((c) => {
      c.set(SEASONS_PER_PAGE_KEY, value.toString());
      return c;
    });
  };

  const setSeasonsOffset = (value: number) => {
    setSearchParams((c) => {
      c.set(SEASONS_OFFSET_KEY, value.toString());
      return c;
    });
  };

  const setParcelsPerPage = (value: number) => {
    setSearchParams((c) => {
      c.set(PARCELS_PER_PAGE_KEY, value.toString());
      return c;
    });
  };

  const setParcelsPage = (value: number) => {
    setSearchParams((c) => {
      c.set(PARCELS_PAGE_KEY, value.toString());
      return c;
    });
  };

  const setTextFilter = (value: string) => {
    setSearchParams((c) => {
      c.set(TEXT_FILTER_KEY, value.toString());
      return c;
    });
    setParcelsPage(PARCELS_PAGE_DEFAULT);
  };

  const setAdvancedFilter = (newFilter: AdvancedFilterType) => {
    setSearchParams((c) => {
      if (newFilter.crops?.length) {
        const cropIds = newFilter.crops.map((crop) => crop.id).join(",");
        c.set(CROPS_KEY, cropIds);
      } else {
        c.delete(CROPS_KEY);
      }
      setParcelsPage(PARCELS_PAGE_DEFAULT);
      return c;
    });

    safeParams.advancedFilter = {
      crops: newFilter.crops ? newFilter.crops.map((crop) => crop.id) : [],
    };
  };

  return {
    seasonsPerPage: safeParams.seasonsPerPage,
    setSeasonsPerPage,
    seasonsOffset: safeParams.seasonsOffset,
    setSeasonsOffset,
    parcelsPerPage: safeParams.parcelsPerPage,
    setParcelsPerPage,
    parcelsPage: safeParams.parcelsPage,
    setParcelsPage,

    textFilter: safeParams.textFilter,
    setTextFilter,
    advancedFilter: safeParams.advancedFilter,
    setAdvancedFilter,
  };
};

export { usePageParams };
