import React, { useState } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getSectionListAdvancedFilter } from "../../../selectors/sectionList.selectors";

import {
  ActiveSubstanceTo,
  CatalogueTo,
  CropTo,
  OrganismTo,
} from "../../../../generated/api/agroevidence";
import CfFilter from "../../../../shared/containers/CfFilter/CfFilter";
import { CropsSelector } from "../../shared/CropsSelector";
import SourceSelector from "../../shared/SourceSelector";

import ActiveSubstancesSelector from "./ActiveSubstancesSelector/ActiveSubstancesSelector";
import { OrganismsSelector } from "./OrganismsSelector/OrganismsSelector";

import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import { CataloguesAdvancedFilterType } from "../../../reducers/sectionList.types";

export interface Props {
  advancedFilter: CataloguesAdvancedFilterType;
  langId: string;
  namespace: string;
}

const PlantProtectionAdvancedFilter = ({
  advancedFilter,
  langId,
  namespace,
}: Props) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] =
    useState<CataloguesAdvancedFilterType>(advancedFilter);

  const handleChangeCrops = (items: CropTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, crops: items });
  };

  const handleChangeOrganisms = (items: OrganismTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, organisms: items });
  };

  const handleChangeActiveSubstances = (items: ActiveSubstanceTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, activeSubstances: items });
  };

  const handleChangeSource = (items: CatalogueTo) => {
    setAdvancedFilterState({ ...advancedFilterState, source: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <SourceSelector
              defaultSource={advancedFilter?.source}
              onChange={handleChangeSource}
              label={
                <FormattedMessage id="Catalogues.table.shared.column.source" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ActiveSubstancesSelector
              defaultValues={advancedFilter?.activeSubstances || []}
              isMultiple
              onChange={handleChangeActiveSubstances}
              label={
                <FormattedMessage id="Catalogues.table.plantProtection.activeSubstances" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CropsSelector
              defaultValues={advancedFilter?.crops || []}
              isMultiple
              label={<FormattedMessage id="common.crop" />}
              onChange={handleChangeCrops}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <OrganismsSelector
              defaultValues={advancedFilter?.organisms || []}
              isMultiple
              isUsed={true}
              onChange={handleChangeOrganisms}
              label={
                <FormattedMessage id="Catalogues.table.plantProtection.column.organismus" />
              }
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  advancedFilter: getSectionListAdvancedFilter(state),
});

export default connect(mapStateToProps)(PlantProtectionAdvancedFilter);

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});
