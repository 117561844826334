import { useEffect, useRef, useState } from "react";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { getIndex } from "../../selectors/monitoring.selectors";

import { setMonitoringIndex } from "../../actions/monitoring.actions";

import { indicesList } from "../../selectors/indices";
import { MonitoringCropIntervals } from "../BioMonitoring";
import { MonitoringCropIntervalsV2 } from "../BioMonitoringV2";

import { IndexType } from "../../../../shared/api/satellite/satellite.types";

type Props = {
  cropIntervals: (MonitoringCropIntervals | MonitoringCropIntervalsV2)[];
  cropIntervalsIsFetching: boolean;
  selectedIntervalId?: string;
  setSelectedInterval: (
    dateFrom: string,
    dateTo?: string,
    selectedId?: string,
  ) => void;
  activeBiomonitoringIndices: IndexType[];
};

const useCommonBioMonitoring = ({
  activeBiomonitoringIndices,
  cropIntervals,
  cropIntervalsIsFetching,
  selectedIntervalId,
  setSelectedInterval,
}: Props) => {
  const dispatch = useDispatch();

  const index = useSelector(getIndex);

  const [isInit, setIsInit] = useState(false);

  const prevCropIntervalsRef = useRef(cropIntervals);

  const activeIndicesList = indicesList.filter((indexItem) =>
    activeBiomonitoringIndices?.includes(indexItem.id),
  );

  useEffect(() => {
    if (!isInit && activeBiomonitoringIndices?.length) {
      setIsInit((prev) => !prev);
    }
  }, [isInit, activeBiomonitoringIndices]);

  useEffect(() => {
    if (cropIntervals.length && !cropIntervalsIsFetching) {
      const cropIntervalsHasChanged = !_.isEqual(
        prevCropIntervalsRef.current,
        cropIntervals,
      );
      if (cropIntervalsHasChanged) {
        const curr = cropIntervals.find((c) => c.id === selectedIntervalId);
        const toSelect = curr ?? cropIntervals[0];
        setSelectedInterval(toSelect.from, toSelect.to, toSelect.id);
      }
      prevCropIntervalsRef.current = cropIntervals;
    }
  }, [
    cropIntervals,
    cropIntervalsIsFetching,
    selectedIntervalId,
    setSelectedInterval,
  ]);

  useEffect(() => {
    if (
      activeBiomonitoringIndices?.length &&
      !activeBiomonitoringIndices?.includes(index as IndexType)
    ) {
      const newIndex = activeBiomonitoringIndices[0];
      dispatch(setMonitoringIndex(newIndex));
    }
  }, [activeBiomonitoringIndices, dispatch, index]);

  return {
    activeIndicesList,
    isInit,
  };
};

export { useCommonBioMonitoring };
