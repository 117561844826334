import React from "react";

import { Grid, Paper } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import Chart from "../../../components/BioMonitoring/Chart";
import ChartInfoSubheading from "../../../components/BioMonitoring/ChartInfoSubheading";
import SectionHeader from "../../../components/SectionHeader";
import { IndiceType } from "../../../selectors/indices";
import { ChartDataOrMonitoringData } from "../../BioMonitoring";
import { ChartDataOrMonitoringDataV2 } from "../../BioMonitoringV2";
import { ChartDesignDataType } from "../useChartDesignData";

import { IndexType } from "../../../../../shared/api/satellite/satellite.types";

type Props = {
  graphData: (ChartDataOrMonitoringData | ChartDataOrMonitoringDataV2)[];
  index: IndiceType;
  isFetchingData: boolean;
  chartDesignData: ChartDesignDataType;
};

const ChartSection = ({
  chartDesignData,
  graphData,
  index,
  isFetchingData,
}: Props) => {
  const intl = useTypedIntl();

  return (
    <Grid data-test="bio-monitoring-graph" item xs={12}>
      <Paper>
        <SectionHeader
          infoDialogContent={<ChartInfoSubheading index={index} />}
          testId="bio-monitoring-graph-header"
          dialogHeadingTranslation={
            <div>
              <FormattedMessage id={`BioMonitoring.${index}`} />
              {index === IndexType.SAVI &&
                ` (${intl.formatMessage({ id: `BioMonitoring.${index}.meaning` })})`}
            </div>
          }
          headingTranslation={
            <FormattedMessage id="BioMonitoring.AverageValues.heading" />
          }
        />
        <Chart
          chartDesignData={chartDesignData}
          data={graphData}
          isFetching={isFetchingData}
        />
      </Paper>
    </Grid>
  );
};

export { ChartSection };
