import React from "react";

import { makeStyles } from "@mui/styles";

import {
  DashboardParcelTo,
  DashboardSeasonTo,
  InternalCropTo,
} from "../../../../../generated/api/satellite";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import { SelectObject } from "../../../../../shared/components/form/FormSelect/types";
import { useDelayedLoader } from "../../../../hooks/useDelayedLoader";
import { useUpdateSeasonParcelMutation } from "../../ParcelDashboard.api";
import { CropCell } from "../crops/CropCell";
import { YieldCell } from "../yield/YieldCell";

import { SeasonCellIconBar } from "./SeasonCellIconBar";

type Props = {
  parcel: DashboardParcelTo;
  cropsOptions: SelectObject<InternalCropTo>[];
  season: DashboardSeasonTo;
  hasAssignedParcel: boolean;
  queryKey: (string | number | string[] | undefined)[];
};

const SeasonCell = ({
  cropsOptions,
  hasAssignedParcel,
  parcel,
  queryKey,
  season,
}: Props) => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();

  const seasonParcelMutation = useUpdateSeasonParcelMutation({
    queryKey,
    parcelId: parcel.parcelId,
    seasonId: season.seasonId,
  });

  const { isDelayed } = useDelayedLoader(seasonParcelMutation.isPending, 600);

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader classes={loaderClasses} size={32} />;
  } else {
    Jsx = (
      <>
        <CropCell
          cropsOptions={cropsOptions}
          parcel={parcel}
          season={season}
          seasonParcelMutation={seasonParcelMutation}
        />
        <YieldCell
          parcel={parcel}
          season={season}
          seasonParcelMutation={seasonParcelMutation}
        />
      </>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>{Jsx}</div>
      {hasAssignedParcel && (
        <SeasonCellIconBar
          data={parcel}
          products={parcel.products ?? []}
          season={season}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  header: {
    width: "100%",
  },
}));

const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export { SeasonCell };
