import React from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

type Props = {
  items: unknown[];
  selectedIndex?: number;
  shiftSelectedItem: (val: boolean) => void;
  reversedMode?: boolean;
};

const ArrowsShift = ({
  items,
  reversedMode,
  selectedIndex,
  shiftSelectedItem,
}: Props) => {
  const classes = useStyles();

  const leftIsDisabled = !items.length || selectedIndex === items.length - 1;
  const rightIsDisabled = selectedIndex === 0;

  const leftOnClick = () => shiftSelectedItem(true);
  const rightOnClick = () => shiftSelectedItem(false);

  return (
    <>
      <IconButton
        className={classes.arrowButton}
        disabled={reversedMode ? rightIsDisabled : leftIsDisabled}
        onClick={reversedMode ? rightOnClick : leftOnClick}
        size="large"
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        className={classes.arrowButton}
        disabled={reversedMode ? leftIsDisabled : rightIsDisabled}
        onClick={reversedMode ? leftOnClick : rightOnClick}
        size="large"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </>
  );
};

const useStyles = makeStyles(() => ({
  arrowButton: {
    padding: 4,
  },
}));

export { ArrowsShift };
