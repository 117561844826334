import React from "react";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { UnitType } from "../../../../../generated/api/agroevidence";

interface Props {
  defaultValues: UnitType | null;
  error: boolean;
  isEditing: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UnitTypeRadioGroup = ({
  defaultValues,
  error = false,
  isEditing,
  onChange,
}: Props) => {
  const classes = useStyles();

  return (
    <FormControl>
      <FormLabel className={classes.radioGroupLabel} disabled={!isEditing}>
        <FormattedMessage id="Catalogues.fertilizers.detail.unitType" />
      </FormLabel>
      <RadioGroup name="unitType" onChange={onChange} value={defaultValues}>
        <FormControlLabel
          control={<Radio size="small" />}
          disabled={!isEditing}
          value={UnitType.WEIGHT}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage
              id={`Catalogues.fertilizers.detail.unitType.${UnitType.WEIGHT}`}
            />
          }
        />
        <FormControlLabel
          control={<Radio size="small" />}
          disabled={!isEditing}
          value={UnitType.VOLUME}
          classes={{
            label: classes.radioGroupLabel,
          }}
          label={
            <FormattedMessage
              id={`Catalogues.fertilizers.detail.unitType.${UnitType.VOLUME}`}
            />
          }
        />
      </RadioGroup>
      {error && (
        <div className={classes.error}>
          <FormattedMessage id="validation.required" tagName="span" />
        </div>
      )}
    </FormControl>
  );
};

export { UnitTypeRadioGroup };

const useStyles = makeStyles((theme: Theme) => ({
  radioGroupLabel: {
    fontSize: 13,
    marginBottom: 0,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "12px",
  },
}));
