import angular from "angular";

import PrecisionController from "./precision.controller";

const precisionComponent = {
  bindings: {
    farmId: "<",
    farm: "<",
  },
  controller: PrecisionController,
};

export default angular
  .module("app.precision", [])
  .config(config)
  .component("precisionComponent", precisionComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider) {
  $stateProvider.state("farm.active.precision", {
    url: "/precision-farming{path:any}",
    views: {
      farm: {
        component: "precisionComponent",
      },
    },
  });
}
