import React from "react";

import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

import CfChartWrapper from "../../../../shared/components/charts/CfChartWrapper/CfChartWrapper";
import { ChartDataOrMonitoringData } from "../../containers/BioMonitoring";
import { ChartDesignDataType } from "../../containers/biomonitoring/useChartDesignData";
import { ChartDataOrMonitoringDataV2 } from "../../containers/BioMonitoringV2";
import XAxisLabel from "../XAxisLabel";

import BioMonitoringTooltip from "./BioMonitoringTooltip";

interface Props {
  data: (ChartDataOrMonitoringData | ChartDataOrMonitoringDataV2)[];
  isFetching: boolean;
  chartDesignData: ChartDesignDataType;
  innerChartDataKey?: string;
}

const Chart = ({
  chartDesignData,
  data,
  innerChartDataKey = "value",
  isFetching,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  const yAxisProps = {
    ticks: chartDesignData.ticksByIndex,
    tickLine: false,
    tick: { fontSize: 12 },
    allowDataOverflow: true,
    padding: { top: 1, bottom: 1 },
    domain: chartDesignData.domainByIndex,
    width: 40,
  };

  const xAxisProps = {
    dataKey: "dateFrom",
    tickLine: false,
    domain: ["dataMin", "dataMax"],
  };

  const defaultCartesianGridProps = {
    strokeDasharray: "2 4",
    strokeWidth: 1,
    stroke: "#CACACA",
  };

  const innerChartProps = {
    isAnimationActive: false,
    dataKey: innerChartDataKey,
    fill: "#e37767",
    fillOpacity: 1,
    stroke: chartDesignData.color,
    strokeWidth: 2,
  };

  const tooltipProps = {
    filterNull: false,
    offset: 50,
  };

  const formatDate = (value: string) => `${intl.formatDate(value)}`;

  return (
    <CfChartWrapper
      data={data}
      isFetching={isFetching}
      classes={{
        wrapper: classes.wrapper,
      }}
    >
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart
          data={data}
          dataKey={innerChartDataKey}
          margin={{ top: 0, right: 40, left: 15, bottom: 10 }}
        >
          <Tooltip
            {...tooltipProps}
            wrapperStyle={{ zIndex: 1 }}
            content={
              <BioMonitoringTooltip tooltipValueColor={chartDesignData.color} />
            }
          />

          <XAxis
            tick={<XAxisLabel formatter={formatDate} textAnchor="middle" />}
            {...xAxisProps}
          />
          <YAxis {...yAxisProps} />
          <Line {...innerChartProps} r={0.5} type="monotoneX" />
          <CartesianGrid {...defaultCartesianGridProps} strokeLinecap="round" />
        </ComposedChart>
      </ResponsiveContainer>
    </CfChartWrapper>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 250,
  },
}));

export default Chart;
