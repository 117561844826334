import { z } from "zod";

import { useSearchParams } from "../../../../precision-farming/hooks/useSearchParams";

const paramsSchema = z.object({
  seasonId: z.coerce.string().optional(),
});

const usePageParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rawParams = Object.fromEntries(searchParams);

  const safeParamsResult = paramsSchema.safeParse({
    ...rawParams,
  });

  const safeParams = safeParamsResult.success ? safeParamsResult.data : {};

  const setSeasonId = (value?: string) => {
    setSearchParams((c) => {
      c.set("seasonId", value ? value.toString() : "");
      return c;
    });
  };

  return {
    seasonId: safeParams.seasonId,
    setSeasonId,
  };
};

export { usePageParams };
