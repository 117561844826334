import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { HistoryPrevState } from "./types";

const useHistoryPreviousState = () => {
  const location = useLocation<HistoryPrevState>();

  const [previousState, setPreviousState] = useState<HistoryPrevState>({});

  useEffect(() => {
    setPreviousState({
      previousLocation: location.state?.previousLocation,
    });
  }, []);

  return previousState;
};

export { useHistoryPreviousState };
