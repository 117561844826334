import React, { useContext, useState } from "react";

import CloseIcon from "@mui/icons-material/Clear";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { FormattedMessage } from "react-intl";

import { useFarmIds } from "../../../shared/api/client.utils";
import CfFormikDatePicker from "../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import FormSelect from "../../../shared/components/form/FormSelect/FormSelect";
import { SelectLabel } from "../../../shared/components/form/FormSelect/SelectLabel";
import { SELECT_EMPTY_OBJ } from "../../../shared/components/form/FormSelect/types";
import FormTextField from "../../../shared/components/form/FormTextField/FormTextField";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useTypedIntl } from "../../../shared/hooks/useTypedIntl";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import { Sidebar } from "../../components/sidebar/Sidebar";

import {
  precisionSeasonsQuery,
  satelliteDashboardKey,
  useCreateSeasonMutation,
} from "./ParcelDashboard.api";

type FormInitType = {
  name: string;
  start?: moment.Moment;
  seasonToCopy?: string;
};

type Props = {
  onCloseClick: () => void;
  page: number;
  open: boolean;
};

const NewSeasonForm = ({ onCloseClick, open, page }: Props) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const showSnackbar = useContext(SnackbarContext);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const intl = useTypedIntl();
  const { farmIds } = useFarmIds();

  const createSeasonMutation = useCreateSeasonMutation();

  const initialValues: FormInitType = {
    name: "",
    start: undefined,
    seasonToCopy: "",
  };

  const seasons = useQuery(
    precisionSeasonsQuery({
      farmIds,
      "sort-dir": "desc",
      page: page + 1,
    }),
  );
  const seasonsOptions =
    seasons?.data?.data.map((s) => ({
      key: s.id,
      value: s.name,
    })) ?? [];

  const validate = (values: typeof initialValues) => {
    const errors: { [key in keyof typeof values]?: React.ReactNode } = {};
    if (!values?.name) {
      errors.name = <FormattedMessage id="validation.required" />;
    }
    if (!values?.start) {
      errors.start = <FormattedMessage id="validation.required" />;
    } else if (!values?.start?.isValid()) {
      errors.start = <FormattedMessage id="validation.timeNotValid" />;
    }

    return errors;
  };

  const handleCloseForm = () => {
    setHasSubmitted(false);
    onCloseClick();
  };

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm, setSubmitting }: FormikHelpers<typeof initialValues>,
  ) => {
    createSeasonMutation.mutate(
      {
        params: {
          farmIds,
          "source-season-id": values.seasonToCopy,
        },
        payload: {
          name: values.name,
          dateFrom: getShortDateString(values.start),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: satelliteDashboardKey,
          });
          showSnackbar({
            message: (
              <FormattedMessage id="PrecisionFarming.seasons.addSeason.submitted" />
            ),
            isSuccess: true,
          });
          resetForm();
          handleCloseForm();
        },
        onError: (err) => {
          if (err.message.includes("409")) {
            showSnackbar({
              message: (
                <FormattedMessage id="PrecisionFarming.seasons.addSeason.alreadyExists" />
              ),
              isError: true,
            });
          } else {
            showSnackbar({
              message: <FormattedMessage id="common.changesSaved.error" />,
              isError: true,
            });
          }
        },
        onSettled: () => {
          setSubmitting(false);
        },
      },
    );
  };

  return (
    <Sidebar open={open}>
      <aside className={classes.drawerForm} role="presentation">
        <div className={classes.sizeWrapper}>
          <div className={classes.header}>
            <IconButton
              aria-label="close"
              onClick={handleCloseForm}
              size="small"
              style={{ marginRight: "auto" }}
            >
              <CloseIcon />
            </IconButton>
            <span className={classes.headerText}>
              <FormattedMessage id="PrecisionFarming.seasons.addSeason.header" />
            </span>
          </div>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnBlur={hasSubmitted}
            validateOnChange={hasSubmitted}
          >
            {({ errors }) => (
              <Form className={classes.form}>
                <div className={classes.formInputs}>
                  <div>
                    <FormTextField
                      autoFocus={true}
                      className={classes.textField}
                      id="name"
                      margin="dense"
                      name="name"
                      type="text"
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      label={
                        <FormattedMessage id="PrecisionFarming.seasons.addSeason.name" />
                      }
                    />
                  </div>
                  <Field
                    component={CfFormikDatePicker}
                    error={!!errors.start}
                    helperText={errors.start}
                    name="start"
                    label={
                      <FormattedMessage id="PrecisionFarming.seasons.addSeason.start" />
                    }
                  />
                  <FormControl error={undefined} fullWidth>
                    <SelectLabel>
                      <FormattedMessage id="PrecisionFarming.seasons.addSeason.seasonToCopy" />
                    </SelectLabel>
                    <FormSelect
                      disabled={false}
                      name="seasonToCopy"
                      variant="standard"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem
                        key={SELECT_EMPTY_OBJ.key}
                        value={SELECT_EMPTY_OBJ.key}
                      >
                        {intl.formatMessage({ id: "common.notSelected" })}
                      </MenuItem>
                      {seasonsOptions.map((s) => (
                        <MenuItem key={`sel-${s.key}`} value={s.key}>
                          {s.value}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  </FormControl>
                </div>
                <Button
                  color="primary"
                  onClick={() => setHasSubmitted(true)}
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage id="PrecisionFarming.seasons.addSeason.submitButton" />
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </aside>
    </Sidebar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  sizeWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100vw",
  },
  header: {
    background: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    fontSize: 24,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: 24,
  },
  formInputs: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  textField: {
    textAlign: "left",
    width: "100%",
    marginTop: 0,
  },
  inputLabel: {
    fontWeight: 400,
    zIndex: 1,
    pointerEvents: "none",
  },
  drawerForm: {
    width: 600,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#F7F7F7",
  },
}));

export { NewSeasonForm };
