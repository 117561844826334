import { useMemo, useCallback } from "react";

import { useLocation, useHistory } from "react-router-dom";

type URLSearchParamsInit =
  | string
  | Record<string, string | number | boolean | null | undefined>
  | URLSearchParams;

function createSearchParams(init?: URLSearchParamsInit): URLSearchParams {
  if (typeof init === "string") {
    return new URLSearchParams(init);
  }
  if (init instanceof URLSearchParams) {
    return new URLSearchParams(init);
  }
  const params = new URLSearchParams();
  if (init && typeof init === "object") {
    Object.entries(init).forEach(([key, value]) => {
      if (value != null) {
        params.set(key, String(value));
      }
    });
  }
  return params;
}

export function useSearchParams(
  defaultInit?: URLSearchParamsInit,
): [
  URLSearchParams,
  (
    nextInit:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit),
  ) => void,
] {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    const defaultParams = createSearchParams(defaultInit);
    const currentParams = new URLSearchParams(location.search);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of defaultParams.entries()) {
      if (!currentParams.has(key)) {
        currentParams.set(key, value);
      }
    }
    return currentParams;
  }, [location.search, defaultInit]);

  const setSearchParams = useCallback(
    (
      nextInit:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit),
    ) => {
      const newSearchParams =
        typeof nextInit === "function"
          ? createSearchParams(nextInit(searchParams))
          : createSearchParams(nextInit);

      history.push({ ...location, search: `?${newSearchParams.toString()}` });
    },
    [history, location, searchParams],
  );

  return [searchParams, setSearchParams];
}
