import React, { useState } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getMonitoringDataError } from "../../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import { getIndex } from "../../../selectors/monitoring.selectors";

import { setMonitoringIndex } from "../../../actions/monitoring.actions";

import {
  SatelliteParcelV2,
  SeasonWithCropTo,
} from "../../../../../generated/api/satellite";
import { useCropOptions } from "../../../../../precision-farming/routes/services/hooks/useCropOptions";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import { OverallImagesError } from "../../../services/BioMonitoringImageError.services";
import { MonitoringCropIntervalsV2 } from "../../BioMonitoringV2";
import { BiomonitoringIndexTypeSectionV2 } from "../BiomonitoringIndexTypeSection/BiomonitoringIndexTypeSectionV2";
import { CropHarvestedError } from "../CropHarvestedError";
import { HeaderBar } from "../HeaderBar";
import { IndexTypeSectionV2 } from "../IndexTypeSection/IndexTypeSectionV2";
import { useCommonBioMonitoring } from "../useCommonBioMonitoring";
import { usePageParams } from "../usePageParams";

import { isPlanetIndexType } from "./helpers";

import { IndexType } from "../../../../../shared/api/satellite/satellite.types";

export type MonitorIntervalType = {
  dateFrom: string;
  dateTo: string;
  selectedIntervalId?: string;
};

type Props = {
  ngRedirectToSowing: (parcelId: string[]) => void;
  parcelId: string;
  parcel?: SatelliteParcelV2;
};

const BiomonitoringWrapperV2 = ({
  ngRedirectToSowing,
  parcel,
  parcelId,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const index = useSelector(getIndex);
  const error = useSelector(getMonitoringDataError);
  const { seasonId, setSeasonId } = usePageParams();

  const [cropIntervals, setCropIntervals] = useState<SeasonWithCropTo[]>([]);
  const [cropIntervalsIsFetching, setCropIntervalsIsFetching] =
    useState<boolean>(false);

  const { crops } = useCropOptions();

  const mappedCropIntervals: MonitoringCropIntervalsV2[] = cropIntervals.map(
    (i) => {
      const crop = crops.find((c) => c.id === i.cropInternalId);
      return {
        id: i.season.id,
        name: i.season.name,
        from: i.season.dateFrom,
        to: "",
        crop,
      };
    },
  );

  const selectedItem = mappedCropIntervals.find((c) => c.id === seasonId);
  const selectedInterval = {
    dateFrom: selectedItem?.from,
    dateTo: selectedItem?.to,
    selectedIntervalId: selectedItem?.id,
  } as MonitorIntervalType;

  const { activeIndicesList, isInit } = useCommonBioMonitoring({
    cropIntervals: mappedCropIntervals,
    cropIntervalsIsFetching,
    selectedIntervalId: seasonId,
    setSelectedInterval: (dateFrom, dateTo, selectedIntervalId) =>
      setSeasonId(selectedIntervalId),
    activeBiomonitoringIndices:
      (parcel?.activeBiomonitoringIndices as IndexType[]) ?? [],
  });

  const [cropHarvestedError, setCropHarvestedError] = useState<
    OverallImagesError | null | undefined
  >(undefined);

  const isPlanet = isPlanetIndexType(index);

  return (
    <CfErrorPage error={error}>
      <>
        {cropHarvestedError && (
          <CropHarvestedError
            cropHarvestedErrorIcon={cropHarvestedError.icon}
            cropHarvestedErrorId={cropHarvestedError.id}
            ngRedirectToSowing={ngRedirectToSowing}
            parcelId={parcelId}
          />
        )}
        <HeaderBar
          arrowsReversedMode
          cropIntervals={mappedCropIntervals}
          index={index}
          items={activeIndicesList}
          selectedIntervalId={seasonId}
          setMonitoringIndex={(i) => dispatch(setMonitoringIndex(i))}
          getItemViewValue={(item: MonitoringCropIntervalsV2) => (
            <span>
              <span className={classes.dateInterval}>{item.name}</span>{" "}
              <span className={classes.cropName}>
                {item.crop?.name ?? <FormattedMessage id="common.noCrop" />}
              </span>
            </span>
          )}
          setMonitoringInterval={(from, to, selectedId) => {
            setSeasonId(selectedId);
          }}
        />
        {isInit && (
          <>
            {isPlanet && (
              <BiomonitoringIndexTypeSectionV2
                cropIntervalsIsFetching={cropIntervalsIsFetching}
                index={index}
                parcel={parcel}
                parcelId={parcelId}
                seasonId={seasonId}
                selectedInterval={selectedInterval}
                setCropHarvestedError={setCropHarvestedError}
                setCropIntervals={setCropIntervals}
                setCropIntervalsIsFetching={setCropIntervalsIsFetching}
              />
            )}
            {!isPlanet && (
              <IndexTypeSectionV2
                cropIntervalsIsFetching={cropIntervalsIsFetching}
                index={index}
                parcel={parcel}
                parcelId={parcelId}
                seasonId={seasonId}
                selectedInterval={selectedInterval}
                setCropHarvestedError={setCropHarvestedError}
                setCropIntervals={setCropIntervals}
                setCropIntervalsIsFetching={setCropIntervalsIsFetching}
              />
            )}
          </>
        )}
      </>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cropName: {
    color: theme.palette.grey[500],
    display: "inline-block",
  },
  dateInterval: {
    textTransform: "lowercase",
    marginRight: 6,
  },
}));

export { BiomonitoringWrapperV2 };
