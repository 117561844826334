import React from "react";

import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { ConnectedProps, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { getPrecisionParcelListFocusedRow } from "../../../shared/api/sentinel/precision/precision.selectors";

import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import { useFocusedTableRowTarget } from "../../../shared/hooks/useFocusedTableRowTarget";
import { PrecisionTableServiceIndicators } from "../components/PrecisionTableServiceIndicators";

import { PRECISION_TABS } from "./PrecisionDetail";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import { PrecisionParcel } from "../../../shared/api/sentinel/precision/precision.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  isInit?: boolean;
  parcel: PrecisionParcel;
  rootUrl: string;
};
type Props = ReduxProps & OwnProps;

const mapStateToProps = (state: PrecisionState) => ({
  focusedRow: getPrecisionParcelListFocusedRow(state),
});

const PrecisionServiceRow = ({
  focusedRow,
  isInit,
  parcel,
  rootUrl,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit,
    rowId: parcel.id.toString(),
  });

  const handleRowClick = (parcel: PrecisionParcel) => {
    let tabPath;
    if (parcel.managementZones) {
      tabPath = PRECISION_TABS.zoneManagement.path;
    } else if (parcel.biomonitoring || parcel.internalBiomonitoring) {
      tabPath = PRECISION_TABS.monitoring.path;
    } else if (parcel.soilSamples) {
      tabPath = PRECISION_TABS.soilSamples.path;
    } else if (parcel.yieldMaps) {
      tabPath = PRECISION_TABS.yieldMaps.path;
    }

    history.push(`${rootUrl}/${parcel.id}/${tabPath}`);
  };

  return (
    <TableRow
      className={classes.pointer}
      hover
      onClick={() => handleRowClick(parcel)}
      ref={targetElementRef}
    >
      <CfTableCell name="name">
        <span className={classes.name}>{parcel.localName}</span>
      </CfTableCell>
      <CfTableCell classes={{ root: classes.column }} name="blockNr">
        <span>{parcel.blockNr}</span>
      </CfTableCell>
      <CfTableCell classes={{ root: classes.column }} name="area">
        <span>
          <CfFormattedNumber value={parcel.area || 0} />
        </span>
      </CfTableCell>
      <CfTableCell name="services">
        <PrecisionTableServiceIndicators parcel={parcel} rootUrl={rootUrl} />
      </CfTableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(() => ({
  name: {
    paddingLeft: 15,
    fontWeight: 500,
  },
  pointer: {
    cursor: "pointer",
  },
  column: {
    textAlign: "right",
  },
}));

const connector = connect(mapStateToProps);
export default connector(PrecisionServiceRow);
