import React, { Suspense, useState } from "react";

import { Switch, Route } from "react-router-dom";

import { LANGUAGE_ID, LANG_KEYS } from "../../../shared/lang/lang.constants";
import { PRECISION_URLS } from "../precision.constants";

import { Dashboard } from "../../../precision-farming/routes/dashboard/Dashboard";
import { ParcelImportTree } from "../../../precision-farming/routes/parcel-import/ParcelImport";
import { PrecisionServicesNew } from "../../../precision-farming/routes/services/PrecisionServicesNew";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import BaseContainer from "../../../shared/containers/BaseContainer/BaseContainer";
import { Detail as AsAppliedDetail } from "../components/AsApplied/AsAppliedDetail/Detail";
import { useIsPrecisionFarmingActive } from "../hooks/useIsPrecisionFarmingActive";
import translationsCZ from "../lang/locale-cs-CZ.json";
import translationsEL from "../lang/locale-el-GR.json";
import translationsEN from "../lang/locale-en-US.json";
import translationsES from "../lang/locale-es-ES.json";
import translationsHU from "../lang/locale-hu-HU.json";
import translationsPL from "../lang/locale-pl-PL.json";
import translationsRO from "../lang/locale-ro-RO.json";

import PrecisionAsApplied from "./PrecisionAsApplied";
import PrecisionDetail from "./PrecisionDetail";
import PrecisionDetailV2 from "./PrecisionDetailV2";
import PrecisionFarming from "./PrecisionFarming";
import PrecisionServices from "./PrecisionServices";
import PrecisionServicesV2 from "./PrecisionServicesV2";
import { PRECISION_LISTING_TABS } from "./PrecisionTabs";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

type Props = {
  farmId: string;
  langId: LANGUAGE_ID;
  ngRedirectToUserIntegrations: (farmId: string) => void;
} & Record<
  "ngRedirectToSowing" | "ngRedirectToVrf" | "ngRedirectToVrs",
  (parcelId: string[]) => void
>;

const PrecisionWrapper = (props: Props) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<
    number | undefined
  >();

  const hasPrecision =
    useIsPrecisionFarmingActive({ isPageInitCall: true, selectedTabIndex }) ??
    false;
  return (
    <BaseContainer langId={props.langId} translations={translations}>
      <Suspense fallback={<CfLoader />}>
        <Switch>
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.main}`}
            render={(routerProps) => (
              <PrecisionFarming
                hasPrecision={hasPrecision}
                setSelectedTabIndex={setSelectedTabIndex}
                tab={PRECISION_LISTING_TABS.SERVICES}
                {...props}
                {...routerProps}
              >
                <PrecisionServices {...props} {...routerProps} />
              </PrecisionFarming>
            )}
          />

          <Route
            path={`/farm/:farmId/${PRECISION_URLS.seasons()}`}
            render={({ match }) => (
              <Switch>
                <Route
                  exact
                  path={`${match.path}/dashboard`}
                  render={(routerProps) => (
                    <PrecisionFarming
                      hasPrecision={hasPrecision}
                      setSelectedTabIndex={setSelectedTabIndex}
                      tab={PRECISION_LISTING_TABS.SEASONS_DASHBOARD}
                      {...props}
                      {...routerProps}
                    >
                      <Dashboard {...props} {...routerProps} />
                    </PrecisionFarming>
                  )}
                />
                <Route
                  path={[`${match.path}/:seasonId`, "*"]}
                  render={(routerProps) => (
                    <PrecisionFarming
                      hasPrecision={hasPrecision}
                      setSelectedTabIndex={setSelectedTabIndex}
                      tab={PRECISION_LISTING_TABS.SEASONS_DASHBOARD}
                      {...props}
                      {...routerProps}
                    >
                      <PrecisionServicesNew {...props} {...routerProps}>
                        <Switch>
                          <Route
                            path={`${routerProps.match.path}/parcel-import`}
                          >
                            <ParcelImportTree />
                          </Route>
                        </Switch>
                      </PrecisionServicesNew>
                    </PrecisionFarming>
                  )}
                />
              </Switch>
            )}
          />

          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.servicesV2()}`}
            render={(routerProps) => (
              <PrecisionFarming
                hasPrecision={hasPrecision}
                setSelectedTabIndex={setSelectedTabIndex}
                tab={PRECISION_LISTING_TABS.SERVICES}
                {...props}
                {...routerProps}
              >
                <PrecisionServicesV2 {...props} {...routerProps} />
              </PrecisionFarming>
            )}
          />
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.services()}`}
            render={(routerProps) => (
              <PrecisionFarming
                hasPrecision={hasPrecision}
                setSelectedTabIndex={setSelectedTabIndex}
                tab={PRECISION_LISTING_TABS.SERVICES}
                {...props}
                {...routerProps}
              >
                <PrecisionServices {...props} {...routerProps} />
              </PrecisionFarming>
            )}
          />
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.asApplied()}`}
            render={(routerProps) => (
              <PrecisionFarming
                hasPrecision={hasPrecision}
                setSelectedTabIndex={setSelectedTabIndex}
                tab={PRECISION_LISTING_TABS.AS_APPLIED}
                {...props}
                {...routerProps}
              >
                <PrecisionAsApplied {...props} {...routerProps} />
              </PrecisionFarming>
            )}
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.asAppliedDetail()}`}
            render={() => <AsAppliedDetail langId={props.langId} />}
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.servicesDetailV2()}`}
            render={(routerProps) => (
              <PrecisionDetailV2 {...props} {...routerProps} />
            )}
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.servicesDetail()}`}
            render={(routerProps) => (
              <PrecisionDetail {...props} {...routerProps} />
            )}
          />
        </Switch>
      </Suspense>
    </BaseContainer>
  );
};

export default PrecisionWrapper;
