import React, { useEffect, useState } from "react";

import { Breakpoint, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedDate } from "react-intl";

import CfStaticMap from "../../../../shared/components/specific/CfStaticMap/CfStaticMap";
import { SetMapForModalParamsV2 } from "../../containers/biomonitoring/TilesContainerV2";
import {
  ChartDataOrMonitoringDataV2,
  GeometriesV2,
} from "../../containers/BioMonitoringV2";

import BioMonitoringImageErrorWrapper from "./BioMonitoringImageErrorWrapperV2";

const getValId = (val = {} as ChartDataOrMonitoringDataV2, zones = true) => {
  const { dateFrom: from, dateTo: to } = val;
  const legislativeCode = val.crop ? val.crop.legislativeCode : undefined;

  return `biomonitoring_${legislativeCode}_${from || ""}_${to || ""}_${
    zones ? "zones" : "index"
  }`;
};

const getImage = (item: ChartDataOrMonitoringDataV2) => ({
  url: item.imgPath,
  extent: [
    item.imgExtent?.minX,
    item.imgExtent?.minY,
    item.imgExtent?.maxX,
    item.imgExtent?.maxY,
  ],
});

interface Props {
  item: ChartDataOrMonitoringDataV2;
  onMapClick: (params: SetMapForModalParamsV2) => void;
  order: number;
  width?: Breakpoint;
}

const BioMonitoringHistoryImageV2 = ({
  item,
  onMapClick,
  order = 0,
  width,
}: Props) => {
  const isMobileView = width === "xs";
  const classes = useStyles(isMobileView);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(false);
    }, order * 250);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageZoneMap = () => (
    <div
      className={classes.zonesMap}
      onClick={() =>
        onMapClick({
          source: getImage(item),
          dateFrom: item.dateFrom,
          dateTo: item.dateTo,
          isImage: true,
        })
      }
    >
      {!hidden && (
        <BioMonitoringImageErrorWrapper item={item}>
          <CfStaticMap
            classes={{ map: classes.map }}
            image={getImage(item)}
            mapId={getValId(item, false)}
          />
        </BioMonitoringImageErrorWrapper>
      )}
    </div>
  );

  const geometryZoneMap = () => (
    <div className={classes.zonesMap}>
      {!hidden && (
        <BioMonitoringImageErrorWrapper isZonesImage={true} item={item}>
          <a
            href="#"
            onClick={() =>
              onMapClick({
                source: item?.zones.map(
                  (z) =>
                    ({
                      geometry: z.geometry,
                      zoneNumber: z.zoneNumber,
                      color: z.color,
                    }) as GeometriesV2,
                ),
                dateFrom: item.dateFrom,
                dateTo: item.dateTo,
              })
            }
          >
            <CfStaticMap
              classes={{ map: classes.map }}
              geometries={item.zones}
              mapId={getValId(item)}
            />
          </a>
        </BioMonitoringImageErrorWrapper>
      )}
    </div>
  );

  return (
    <div className={classes.wrapper} data-test="history-item">
      <div className={classes.images}>
        {imageZoneMap()}
        {item.zones.length > 0 && geometryZoneMap()}
      </div>
      <div className={classes.dateHeading} data-test="date">
        {moment(item.dateFrom).isSame(moment(item.dateTo)) ? (
          <FormattedDate
            day="numeric"
            month="numeric"
            value={item.dateFrom}
            year="numeric"
          />
        ) : (
          <>
            <FormattedDate
              day="numeric"
              month="numeric"
              value={item.dateFrom}
            />
            {" \u2013 "}
            <FormattedDate day="numeric" month="numeric" value={item.dateTo} />
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: "10px 5px 5px 5px",
  },
  dateHeading: {
    textAlign: "center",
    padding: 2,
    fontSize: 13,
  },
  images: {
    display: "flex",
    flexDirection: (isMobileView) => (isMobileView ? "row" : "column"),
  },
  zonesMap: {
    width: 150,
    height: 150,
    margin: 1,
    backgroundColor: theme.palette.grey[100],
    cursor: "pointer",
  },
  map: {
    borderRadius: 0,
    "& .ol-viewport": {
      borderRadius: 0,
    },
  },
}));

export default BioMonitoringHistoryImageV2;
