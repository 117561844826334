import { useEffect } from "react";

import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { PRECISION_URLS } from "../../../../core/precision/precision.constants";

import { SeasonTo } from "../../../../generated/api/satellite";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";

type Props = {
  isInit: boolean;
  seasonsOptions: SelectObject<SeasonTo>[];
  farmId: string;
};

const useSeason = ({ farmId, isInit, seasonsOptions }: Props) => {
  const history = useHistory();
  const { search } = useLocation();

  const { seasonId: seasonIdQueryParam } = useParams<{ seasonId?: string }>();

  const season = seasonsOptions.find((s) => s.key === seasonIdQueryParam);

  useEffect(() => {
    if (isInit) {
      if (!season && seasonsOptions.length > 0) {
        history.replace(
          generatePath(`/farm/${farmId}/${PRECISION_URLS.seasonDetail()}`, {
            seasonId: seasonsOptions[0].key,
          }),
        );
      }
    }
  }, [season, seasonsOptions, isInit, history, farmId]);

  const handleSeasonChange = (obj: SelectObject<SeasonTo>) => {
    const path = generatePath(
      `/farm/${farmId}/${PRECISION_URLS.seasonDetail()}`,
      {
        seasonId: obj.key,
      },
    );
    history.push(`${path}${search}`);
  };

  return {
    season,
    handleSeasonChange,
  };
};

export { useSeason };
