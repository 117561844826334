import React from "react";

import Grid from "@mui/material/Grid";
import { Field, Form, Formik, FormikErrors } from "formik";
import toNumber from "lodash/toNumber";
import { FormattedMessage } from "react-intl";

import {
  RestrictionTo,
  RestrictionType,
  SubtractionResponse,
} from "../../../../../generated/api/agroevidence";
import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import Localization from "../../../../../shared/services/Localization.service";

export interface SubtractableAreasEditFormValues {
  value?: string;
}

type Props = {
  handleClose: () => void;
  maxValue: number;
  opened: boolean;
  subtractableAreas: SubtractionResponse[];
  subtractableAreaType: RestrictionType;
  onAccept: (sa: RestrictionTo) => void;
};

const NewSubtractableAreaDialog = ({
  handleClose,
  maxValue,
  onAccept,
  opened,
  subtractableAreaType,
  subtractableAreas,
}: Props) => {
  const { locale } = useTypedIntl();

  const handleValidate = (values: SubtractableAreasEditFormValues) => {
    const errors: FormikErrors<SubtractableAreasEditFormValues> = {};

    const normalizedValue = Localization.str2numNonFixed(
      values.value as string,
      locale,
    );
    const maxValueHa = toNumber(maxValue.toFixed(2));

    if (!values.value) {
      errors.value = "validation.required";
    } else if (isNaN(Number(normalizedValue)) || Number(normalizedValue) <= 0) {
      errors.value = "validation.positiveNumber";
    } else if (
      Number(normalizedValue) >= maxValueHa &&
      subtractableAreaType === RestrictionType.Absolute
    ) {
      errors.value = "validation.tooBig";
    } else if (
      subtractableAreas.some((sa) => {
        const tolerance = 0.001;
        const value = sa.value ?? 0;
        return (
          Math.abs(Math.round(value * 100) / 100 - Number(normalizedValue)) <=
          tolerance
        );
      })
    ) {
      errors.value = "SubtractableAreaDialog.areaAlreadyInResult";
    }

    return errors;
  };

  const handleSubmit = (values: SubtractableAreasEditFormValues) => {
    const normalizedValue = Localization.str2numNonFixed(
      values.value as string,
      locale,
    );

    onAccept({
      type: subtractableAreaType,
      value: normalizedValue as number,
    });
  };

  const titleId = () => {
    switch (subtractableAreaType) {
      case RestrictionType.Absolute:
        return "NewSubtractableAreaDialog.absoluteHeading";
      case RestrictionType.Boundary:
        return "NewSubtractableAreaDialog.boundaryHeading";
      case RestrictionType.Water:
        return "NewSubtractableAreaDialog.waterHeading";
      case RestrictionType.Landscape:
        return "NewSubtractableAreaDialog.landscapeHeading";
      default:
        return "";
    }
  };

  return (
    <Formik<SubtractableAreasEditFormValues>
      enableReinitialize
      onSubmit={handleSubmit}
      validate={handleValidate}
      initialValues={{
        value: "",
      }}
    >
      {({ errors, resetForm, submitForm, values }) => (
        <CfDialog
          acceptText={<FormattedMessage id="NewSubtractableAreaDialog.add" />}
          cancelText={<FormattedMessage id="common.cancel" />}
          maxWidth="xs"
          onAccept={submitForm}
          onClose={handleClose}
          opened={opened}
          title={<FormattedMessage id={titleId()} />}
          onCancel={() => {
            resetForm();
            handleClose();
          }}
        >
          <Form>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <CfFormControl>
                  <Field
                    component={CfFormikTextField}
                    name="value"
                    value={values.value}
                    helperText={
                      !!errors.value && <FormattedMessage id={errors.value} />
                    }
                    label={
                      subtractableAreaType === RestrictionType.Absolute ? (
                        <FormattedMessage id="SubtractableArea.area" />
                      ) : (
                        <FormattedMessage id="SubtractableArea.distance" />
                      )
                    }
                  />
                </CfFormControl>
              </Grid>
            </Grid>
          </Form>
        </CfDialog>
      )}
    </Formik>
  );
};

export { NewSubtractableAreaDialog };
