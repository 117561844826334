import React, { PropsWithChildren } from "react";

import { alpha, IconButton, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import { COLOR_PRIMARY } from "../../../../../theme";

type Props = {
  isLoading: boolean;
  isLoadingOffset: boolean;
  disabled: boolean;
  noParcelsForSeasons: boolean;
  onClick: () => void;
  seasonsOffset: number;
};

const ArrowButton = ({
  children,
  disabled,
  isLoading,
  isLoadingOffset,
  noParcelsForSeasons,
  onClick,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();

  return (
    <>
      {isLoadingOffset ? (
        <span
          className={classNames(
            classes.arrowButtonPosition,
            loaderClasses.loaderWrapper,
          )}
        >
          <CfLoader classes={{ custom: loaderClasses.custom }} />
        </span>
      ) : (
        <StyledIconButton
          disabled={disabled}
          onClick={onClick}
          size="small"
          sx={isLoading ? { display: "none" } : undefined}
          className={classNames(classes.arrowButtonPosition, {
            [classes.noParcelsForSeasonsArrow]: noParcelsForSeasons,
          })}
        >
          {children}
        </StyledIconButton>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  arrowButtonPosition: {
    position: "absolute",
    right: 0,
    bottom: 0,
    transform: "translateY(100%)",
    borderRadius: "100%",
  },
  noParcelsForSeasonsArrow: {
    left: 0,
    right: "unset",
  },
}));

const useLoaderStyles = makeStyles(() => ({
  loaderWrapper: {
    height: 48,
    width: 48,
  },
  custom: {
    display: "flex",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: alpha(COLOR_PRIMARY.cleverfarm.main, 0.8),
  "&:hover": {
    backgroundColor: alpha(COLOR_PRIMARY.cleverfarm.main, 0.65),
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(theme.palette.common.black, 0.04),
  },
}));

export { ArrowButton };
