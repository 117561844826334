import React from "react";

import { connect } from "react-redux";

import { getPrecisionParcelListTextFilter } from "../selectors/precisionParcelList.selectors";

import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";
import { PRECISION_URLS } from "../precision.constants";

import { NAMESPACE as namespace } from "../reducer/precisionParcelList.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import { RsaaApiError } from "../../../types";
import { useIsPrecisionFarmingActive } from "../hooks/useIsPrecisionFarmingActive";

import NoPrecision from "./NoPrecision";
import PrecisionServicesTable from "./PrecisionServicesTable";
import { usePrecisionListStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

interface Props {
  error?: RsaaApiError;
  farmId: string;
  langId: LANGUAGE_ID;
  textFilter: string;
}

export const PrecisionServicesV2 = ({
  error,
  farmId,
  langId,
  textFilter,
}: Props) => {
  const classes = usePrecisionListStyles();
  const { onChange } = useCfTextFilter(namespace);
  const hasPrecision = useIsPrecisionFarmingActive({ isPageInitCall: false });
  let Jsx = <CfLoader />;

  if (hasPrecision !== undefined && !hasPrecision) {
    Jsx = <NoPrecision langId={langId} />;
  }

  if (hasPrecision) {
    Jsx = (
      <div>
        <div className={classes.toolbar}>
          <CfTextFilter
            initialValue={textFilter}
            name="precision-list-text-filter"
            onChange={onChange}
            translId="PrecisionListTextFilter.placeholder"
          />
        </div>
        <PrecisionServicesTable
          rootUrl={`/farm/${farmId}/${PRECISION_URLS.servicesV2()}`}
        />
      </div>
    );
  }

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>{Jsx}</div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  textFilter: getPrecisionParcelListTextFilter(state),
});

export default connect(mapStateToProps)(PrecisionServicesV2);
