import React, { useContext, useMemo } from "react";

import { makeStyles } from "@mui/styles";
import { Form, Field, Formik, FormikErrors } from "formik";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RSAAResultAction } from "redux-api-middleware";

import {
  fetchSeeds,
  createSeed,
  updateSeed,
} from "../../actions/catalogues.actions";

import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import { COLOR_FONT } from "../../../theme";
import { AsyncFn, Thunk } from "../../../types";
import { CropsSelector } from "../shared/CropsSelector";

import { CataloguesState } from "../../../reducers/catalogues.reducer.types";
import {
  CropSeedCreateTo,
  CropSeedPatchTo,
  CropSeedTo,
  CropTo,
} from "../../../shared/api/agroevidence/agroevidence.types";

export interface SeedsEditFormValues {
  crop?: CropTo;
  varietyName?: string;
}

const handleValidate = (values: SeedsEditFormValues) => {
  const errors: FormikErrors<SeedsEditFormValues> = {};
  if (!values.varietyName) {
    errors.varietyName = "validation.required";
  }
  if (!values.crop) {
    errors.crop = "validation.required";
  }
  return errors;
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  data?: CropSeedTo;
  handleClose: () => void;
  isDeleting?: boolean;
  isExisting?: boolean;
  opened: boolean;
};
type Props = ReduxProps & OwnProps;

const SeedsEditDialog = ({
  createSeed,
  data,
  fetchSeeds,
  handleClose,
  isDeleting = false,
  isExisting = false,
  opened,
  updateSeed,
}: Props) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const initialValues: Partial<SeedsEditFormValues> = useMemo(
    () => ({
      varietyName: data?.varietyName ?? "",
      crop: data?.crop ?? undefined,
    }),
    [data],
  );

  const handleSubmit = (values: SeedsEditFormValues) => {
    if (isDeleting) {
      (updateSeed as unknown as AsyncFn<string, CropSeedPatchTo>)(data?.id, {
        isHidden: true,
      }).then((res: RSAAResultAction<CropSeedTo>) => handleResponse(res));
    } else if (!isExisting && values.crop) {
      const params: CropSeedCreateTo = {
        varietyName: values.varietyName,
        cropId: values.crop.id,
      };
      (createSeed as unknown as AsyncFn<CropSeedCreateTo>)(params).then(
        (res: RSAAResultAction<CropSeedTo>) => handleResponse(res, true),
      );
    } else {
      const params: CropSeedPatchTo = {
        varietyName: values.varietyName,
      };
      (updateSeed as unknown as AsyncFn<string, CropSeedPatchTo>)(
        data?.id,
        params,
      ).then((res: RSAAResultAction<CropSeedTo>) => handleResponse(res));
    }
    handleClose();
  };

  const handleResponse = (
    res: RSAAResultAction<CropSeedTo>,
    newAction = false,
  ) => {
    if (!res.error) {
      showSnackbar({
        message: (
          <FormattedMessage
            id={successMessageId()}
            values={{
              cropName: res.payload.name,
              varietyName: res.payload.varietyName,
              b: (chunks: string) => <b>{chunks}</b>,
            }}
          />
        ),
        isSuccess: true,
      });
      const validOn = getShortDateString();
      fetchSeeds(validOn);
      return;
    }
    showSnackbar({
      message: (
        <FormattedMessage
          id={`Catalogues.seeds.${newAction ? "createError" : "updateError"}`}
        />
      ),
      isError: true,
    });
  };

  const titleId = () => {
    if (isDeleting) {
      return "Catalogues.seeds.dialog.title.delete";
    } else if (isExisting) {
      return "Catalogues.seeds.dialog.title.update";
    } else {
      return "Catalogues.seeds.dialog.title.create";
    }
  };

  const successMessageId = () => {
    if (isDeleting) {
      return "Catalogues.seeds.deleteSuccess";
    } else if (isExisting) {
      return "Catalogues.seeds.updateSuccess";
    } else {
      return "Catalogues.seeds.createSuccess";
    }
  };

  return (
    <Formik<SeedsEditFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      {({ errors, resetForm, setFieldValue, submitForm, values }) => {
        const handleChangeCrop = (crop: CropTo) => {
          setFieldValue("crop", crop);
        };

        return (
          <CfDialog
            cancelText={<FormattedMessage id="common.cancel" />}
            onAccept={submitForm}
            opened={opened}
            title={<FormattedMessage id={titleId()} />}
            acceptText={
              <FormattedMessage
                id={`common.${isDeleting ? "delete" : "save"}`}
              />
            }
            onCancel={() => {
              resetForm();
              handleClose();
            }}
          >
            <Form>
              {isExisting && (
                <div className={classes.cropField}>
                  <span className={classes.updateInfo}>
                    <FormattedMessage
                      id={`Catalogues.seeds.dialog.${
                        isDeleting ? "delete" : "update"
                      }.info`}
                    />
                  </span>
                </div>
              )}
              <div className={classes.cropField}>
                <CropsSelector
                  defaultValues={values.crop}
                  disabled={isExisting}
                  error={!!errors.crop}
                  onChange={handleChangeCrop}
                  label={
                    <FormattedMessage id="Catalogues.seeds.dialog.cropName" />
                  }
                />
              </div>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  customClasses={{ root: classes.varietyNameField }}
                  disabled={isDeleting}
                  name="varietyName"
                  helperText={
                    !!errors.varietyName && (
                      <FormattedMessage id={errors.varietyName} />
                    )
                  }
                  label={
                    <FormattedMessage id="Catalogues.seeds.dialog.varietyName" />
                  }
                />
              </CfFormControl>
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      createSeed,
      updateSeed,
      fetchSeeds,
    },
    dispatch,
  );

const connector = connect(null, mapDispatchToProps);

export default connector(SeedsEditDialog);

const useStyles = makeStyles(() => ({
  cropField: {
    marginBottom: 20,
    marginRight: 20,
  },
  updateInfo: {
    color: COLOR_FONT.main,
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
  },
  varietyNameField: {
    width: 470,
  },
}));
